import getConfig from 'next/config'
import fetch from 'isomorphic-unfetch'
import { i18n } from '../i18n'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

const generateHeaders = token => ({
  Authorization: token && `JWT ${token}`,
  Accept: 'application/json',
  'Content-type': 'application/json',
  'Accept-Language': i18n.language,
  Secret: publicRuntimeConfig.secret
})

export const getName = async (token) => {
  let profileUrl = '/api/v1/me/'

  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    profileUrl = `${serverRuntimeConfig.serverAddress}${profileUrl}`
  }

  try {
    if (token) {
      const res = await fetch(profileUrl, {
        headers: generateHeaders(token),
        method: 'GET'
      })

      const resJson = await res.json()
      if (!res.ok) {
        return Promise.reject(resJson)
      }
      resJson.id = resJson.profile_id
      return resJson
    }
  } catch (error) {
    console.error(error)
  }
  return null
}

export const getProfile = async (token) => {
  let profileUrl = '/api/v1/profiles/me/'

  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    profileUrl = `${serverRuntimeConfig.serverAddress}${profileUrl}`
  }

  try {
    if (token) {
      const res = await fetch(profileUrl, {
        headers: generateHeaders(token),
        method: 'GET'
      })

      const resJson = await res.json()
      if (!res.ok) {
        return Promise.reject(resJson)
      }
      return resJson
    }
  } catch (error) {
    console.error(error)
  }
  return null
}

export const auth = async (credentials, authType) => {
  let regUrl = `/api/v1/auth/${authType}/`

  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    regUrl = `${serverRuntimeConfig.serverAddress}${regUrl}`
  }

  try {
    const res = await fetch(regUrl, {
      headers: generateHeaders(),
      method: 'POST',
      body: JSON.stringify(credentials)
    })

    const resJson = await res.json()
    if (!res.ok) {
      return Promise.reject(resJson)
    }

    if (resJson && resJson.user) {
      resJson.user.id = resJson.user.profile_id
    }

    return resJson
  } catch (error) {
    console.error(error)
  }
  return null
}
